(function(w,d){

    w.lazyFunctions = {
        faleconosco: async el => {
            const {FaleConosco} = await import('./faleconosco');
            return Promise.resolve(FaleConosco(el));
        },
        galeria: async el => {
            const {Galeria} = await import('./galeria');
            return Promise.resolve(Galeria(el));
        },
        swaiper_a1: async el => {
            const {SwiperA1} = await import('./swiper_a1');
            return Promise.resolve(SwiperA1(el));
        }
    };
    
    w.addEventListener("LazyLoad::Initialized", event => w.lazyLoadInstance = event.detail.instance, false);

    w.canvasShowElements = () => {
        console.log(document.querySelector('body').classList.remove('offc'));
    };

    w.canvasHideElements = () => {
        console.log(document.querySelector('body').classList.add('offc'));
    };

    const offcanvasMenu = document.getElementById('offcanvasMenu');
    if(offcanvasMenu) {

        offcanvasMenu.addEventListener('show.bs.offcanvas', function () {
            return w.canvasHideElements();
        });

        offcanvasMenu.addEventListener('hidden.bs.offcanvas', function () {
            // document.querySelector('#q').value = '';
            return w.canvasShowElements();
        });
    }

    const offcanvasSearch = document.getElementById('offcanvasBusca');
    if(offcanvasSearch) {

        offcanvasSearch.addEventListener('show.bs.offcanvas', function () {
            return w.canvasHideElements();
        });

        offcanvasSearch.addEventListener('shown.bs.offcanvas', function () {
            document.querySelector('#q').focus();
        });

        offcanvasSearch.addEventListener('hidden.bs.offcanvas', function () {
            document.querySelector('#q').value = '';
            return w.canvasShowElements();
        });
    }

    document.addEventListener('minimal-user-interaction', function (event) {
        console.log('minimal-user-interaction');
        const __loader = async () => {
            try {
                const LazyLoad = await import('vanilla-lazyload');
                const lazy = new LazyLoad({
                    unobserve_entered: true,
                    elements_selector: ".lazy, iframe[data-src], [class^='fb-'], .instagram-media, .twitter-tweet, [class^='sharethis-inline-share-buttons']",
                    callback_enter: (element) => {
                        if(element.hasAttribute('data-lazy-function')) {
                            let lazyFunctionName = element.getAttribute("data-lazy-function");
                            let lazyFunction = w.lazyFunctions[lazyFunctionName];
                            if (!lazyFunction) return;
                            return lazyFunction(element);
                        } else {
    
                            window.dataLayer = window.dataLayer || [];
    
                            let classList = String(element.classList);
                            let eventName = '';
                            let removeEntered = false;
    
                            if(classList.match(/fb-*/gi)) {
                                eventName = 'LoaderFacebookSDK';
                                removeEntered = true;
                            } else if(classList.match(/instagram-media/gi)) {
                                eventName = 'LoaderInstagram';
                            } else if(classList.match(/taboola/gi)) {
                                eventName = 'LoaderTaboolaFeed';
                            } else if(classList.match(/twitter-tweet/gi)) {
                                eventName = 'LoaderTwitter';
                            } else if(classList.match(/sharethis-inline-share-buttons/gi)) {
                                eventName = 'LoaderShareButtons';
                            }
    
                            if(eventName) {
                                // console.log(eventName);
                                if(removeEntered) element.classList.remove('entered');
                                window.dataLayer.push({'event': eventName});
                            }
    
                        }
                    }
                });
    
                await import('bootstrap/js/dist/offcanvas');
                await import('bootstrap/js/dist/collapse');
                await import('freezeui');
                await import('fslightbox');
        
                const mobile = window.matchMedia("(max-width: 40em) and (orientation: portrait)");
                if(mobile.matches){
                    const btn = document.querySelector('#accordionFlushExample .accordion-button');
                    const item = document.querySelector('#flush-collapseOne');
                    if(btn && item) {
                        // btn.classList.remove('collapsed');
                        btn.classList.add('collapsed');
                        btn.setAttribute('aria-expanded','false');    
                        item.classList.remove('show');
                    }
                }
    
                document.addEventListener('click', function (event) {
                    let retval = true;
                    if(event?.target.classList.contains('fslightbox')) {
                        event.preventDefault();
                        if( String( event?.target?.dataset?.fslightboxSrc ).length > 0 ) {
                            const lightbox = new FsLightbox();
                            lightbox.props.sources = [ event.target.dataset.fslightboxSrc ];
                            lightbox.open();
                            retval = false;
                        }    
                    }
                    return retval;
                }, false);
    
            } catch(err) {
                console.error('erro ao carregar lazyload', err);
            }
        };
        __loader().then(() => console.log('ok')).catch(() => console.error('failed'));
        return false;
    });

    console.log('main app loaded');

    const gtm_container = document.getElementById('gtm-container');
    if(gtm_container) {
        const gtm_id = gtm_container?.dataset?.id || null;
        if(gtm_id) {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',gtm_id.toString());
            console.log('gtm loaded', gtm_id);
        }
    }

    // Promise.all([
    //     import('bootstrap/js/dist/offcanvas'),
    //     import('bootstrap/js/dist/collapse'),
    //     import('freezeui'),
    //     // import('bootstrap/js/dist/tab'),
    //     import('fslightbox')
    //     // import('vanilla-lazyload')
    // ])
    // .then(() => {

    // })
    // .catch(error => {
    //     console.error('erro ao importar componente offcanvas', error)
    // });

})(window,document);